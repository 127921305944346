(function($) {

  var $document = $(document),
      $body = $('body'),
      $overlay = $('[data-component="overlay"]');


  function setOverlayImageContainerHeight(){
    var contentHeight = $overlay.outerHeight(),
        $overlayImage = $overlay.find('.overlay-background-image');
    $overlayImage.height(contentHeight);
  }

  function openOverlay(){
    $overlay.addClass('_active');
    $body.addClass('overlay-open');
    setOverlayImageContainerHeight();
  }

  function closeOverlay(){
    $overlay.removeClass('_active');
    $body.removeClass('overlay-open');
  }

  // Check for cookie here as well
  if ($overlay.length) {
    window.setTimeout(openOverlay, 1000);
    $document.on('click', '[data-role="overlay-close"]', closeOverlay);
    $document.keyup(function(e) {
      if (e.keyCode === 27) { // escape key maps to keycode `27`
        closeOverlay();
      }
    });
  }

})(jQuery);
