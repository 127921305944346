(function($) {

  var $document = $(document),
      $selects = $('[data-component="styled-select"]');

  function handleSelectClick(event){
    var $this = $(this),
        $select = $this.find('select');

    $this.toggleClass('_active');
  }

  function closeMenu(event){
    if( !$(event.target).closest('[data-component="styled-select"]').length ) {
      $selects.removeClass('_active');
    }
  }

  function handleOptionClick(event){
    var $this = $(event.target),
        index = $this.index(),
        $parentStyledSelect = $this.closest('[data-component="styled-select"]'),
        $selectOptions = $parentStyledSelect.find('option'),
        $selectedOption = $($selectOptions[index]);

    // Exit early if the first option was selected as that's just the heading
    if (index === 0) {
      return;
    }

    // Set the selected attribute
    $selectedOption.attr('selected', 'selected');
    // Trigger a change on the select so the normal behavior can take place
    $selectedOption.parent().trigger('change');
  }

  function init(){
    $selects.each(function(index, element){
      var $selectContainer = $(element),
          $select = $(element).find('select'),
          $selectOptions = $select.find('option'),
          $optionsContainer;

      $selectContainer.append('<div class="styled-select-options"></div>');

      $optionsContainer = $selectContainer.find('.styled-select-options');

      $selectOptions.each(function(index, element){
        $optionsContainer.append('<div class="styled-select-option">' + element.innerHTML + '</div>');
      });
    });
  }

  if ($selects.length) {
    init();
    $document.on('click', '[data-component="styled-select"]', handleSelectClick);
    $document.on('click', closeMenu);
    $document.on('click', '.styled-select-option', handleOptionClick);
  }

})(jQuery);
