(function($) {

  var $document = $(document),
      $window = $(window),
      $pinterestComponent = $('[data-component="zoo-pinterest"]'),
      basePinterestUrl = 'https://api.pinterest.com/v1/boards/',
      accessToken = 'AS-EasdzwUjIz-Q4du1m78ofVnxYFF_Og1amuHVDO-nHtcBEagAAAAA',
      tailPinterestUrl = '/pins/?access_token=' + accessToken + '&fields=id%2Clink%2Cnote%2Curl%2Cimage',
      board = $pinterestComponent.data('board') || 'katie_saunders/branding',
      imagesToShow = $pinterestComponent.data('num-images') || 4;

  /**
  * Adjusts the height of the pin images so that it is equal to their width
  */
  function adjustPinHeight(){
    $pinterestComponent.find('a').css('height', function(){ return $(this).width(); });
  }


  /**
  * Adds the pins to the pinterest component given a set of data
  */
  function addPins(data, count){
    var element, title, imgSrc, url;

    for (var i = 0; i < count; i++) {
      title = data.data[i].note;
      imgSrc = data.data[i].image.original.url;
      url = data.data[i].link;

      element = '<a href="' + url + '" title="' + title + '" target="_blank" style="background-image: url(' + imgSrc + ')"></a>';

      $pinterestComponent.append(element);
    }
  }

  // If the pinterest component is on the page, make the ajax request
  if ($pinterestComponent.length) {
    $.ajax({
      url: basePinterestUrl + board + tailPinterestUrl
    })
    .done(function(response){
      addPins(response, imagesToShow);
      adjustPinHeight();
    });
  }

  // Adjust the pin height whenever the window is resized
  // TODO throttle this
  $window.on('resize', adjustPinHeight);

})(jQuery);
