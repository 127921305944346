(function($) {

  var $window = $(window),
      $document = $(document),
      $heroImages = $('[data-component="hero-images"]'),
      $images = $heroImages.find('.image img'),
      $shorterImage,
      $tallerImage;

  // function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  //   var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  //   return { width: srcWidth * ratio, height: srcHeight * ratio };
  // }
  //
  // function adjustHeroImage(){
  //   if ($images.length) {
  //     if ($($images[0]).outerHeight() > $($images[1]).outerHeight()) {
  //       $shorterImage = $($images[1]);
  //       $tallerImage = $($images[0]);
  //     } else{
  //       $shorterImage = $($images[0]);
  //       $tallerImage = $($images[1]);
  //     }
  //
  //     var shorterImageDimensions = calculateAspectRatioFit($shorterImage[0].naturalWidth, $shorterImage[0].naturalHeight, 300, 400),
  //         tallerImageDimensions = calculateAspectRatioFit($tallerImage[0].naturalWidth, $tallerImage[0].naturalHeight, 300, 400);
  //     console.log('shorter', $shorterImage[0].naturalWidth, $shorterImage[0].naturalHeight);
  //     console.log('taller', $tallerImage[0].naturalWidth, $tallerImage[0].naturalHeight);
  //     console.log('shorterImageDimensions', shorterImageDimensions);
  //     console.log('tallerImageDimensions', tallerImageDimensions);
  //
  //     $shorterImage.height($tallerImage.outerHeight());
  //     $shorterImage.find('a, img').height('100%');
  //   }
  // }
  //
  // if ($images.length) {
  //   adjustHeroImage();
  // }
  //
  // $window.on('resize', adjustHeroImage);

})(jQuery);
