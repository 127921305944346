(function($) {

  var $document = $(document),
      $window = $(window),
      $drawers = $('[data-drawer]');

  function toggleDrawer(event){
    var $this = $(event.target),
        drawerId = $this.data('drawer'),
        $drawer = $('#' + drawerId),
        isHidden = $drawer.attr('aria-hidden') === 'true';

    event.preventDefault();

    if (isHidden) {
      $drawer.attr('aria-hidden', 'false');
    } else{
      $drawer.attr('aria-hidden', 'true');
    }

    $drawer.toggleClass('_active');
  }

  function scrollToHash(){
    var hash = window.location.hash,
        $hashed = $(hash);

    $('html, body').animate(
      { scrollTop: $hashed.position().top },
      300
    );
  }

  function checkHash(event){
    var hash = window.location.hash,
        $hashed = $(hash),
        parentDrawerId,
        $drawerTrigger,
        $drawerContent;

    if (hash && $hashed.length) {
      $drawerContent = $hashed.closest('.drawer-content');
      parentDrawerId = $drawerContent.attr('id');

      if (parentDrawerId) {
        $drawerTrigger = $('[data-drawer="' + parentDrawerId + '"]');
        $drawerTrigger.trigger('click');

        /* Listen for a transition! */
        Zoo.Utils.attachPostTransitionEvent($drawerContent[0], scrollToHash);
      } else{
        return;
      }
    } else{
      return;
    }
  }

  $window.on('load', checkHash);
  $document.on('click', '[data-drawer]', toggleDrawer);

})(jQuery);
