(function($) {

  var $document = $(document),
      $mobileNav = $('[data-component="mobile-nav"]'),
      $emptyAnchors;

  function toggleSubNav(event){
    var $this = $(event.target);

    event.preventDefault();

    $this.toggleClass('_active');
  }

  if ($mobileNav.length) {
    $emptyAnchors = $mobileNav.find('[href="#"]');

    $document.on('click', '[data-component="mobile-nav"] [href="#"]', toggleSubNav);
  }

})(jQuery);
