(function($) {

  /* From Modernizr */
  function whichTransitionEvent(){
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
      'transition':'transitionend',
      'OTransition':'oTransitionEnd',
      'MozTransition':'transitionend',
      'WebkitTransition':'webkitTransitionEnd'
    };

    for(t in transitions){
      if( el.style[t] !== undefined ){
          return transitions[t];
      }
    }
  }

  function attachPostTransitionEvent(element, callback){
    var transitionEvent = whichTransitionEvent();
    transitionEvent && element.addEventListener(transitionEvent, callback);
  }

  Zoo.Utils.whichTransitionEvent = whichTransitionEvent;
  Zoo.Utils.attachPostTransitionEvent = attachPostTransitionEvent;

})(window, jQuery);
